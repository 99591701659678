import React from "react"
import NewsletterForm from "../../components/form/NewsletterForm"
import Layout from "../../components/layouts/Default"
import H2 from "../../components/typography/H2"
import P from "../../components/typography/P"
import Container from "../../components/common/Container"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Zum Newsletter anmelden" desc="">
      <Container>
        <div className="max-w-2xl mx-auto">
          <H2 className="mb-4">
            Newsletter der Seo Agentur Stuttgart abonnieren
          </H2>
          <P className="mb-2">
            Du möchtest mehr Kunden durch ein Google Seite 1 Ranking? Melden
            Dich zu unserem Newsletter an, um auf dem Laufenden zu bleiben.
          </P>
          <NewsletterForm />
        </div>
      </Container>
    </Layout>
  )
}
